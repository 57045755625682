
<template>
  <div class="container">
    <div class="title">
      <span class="title-left"> <img src="../../../assets/img/company//BUs.png" alt="" style="margin-right:8px">
        Business Operations</span>


    </div>
    <div class="table">
      <div class="right_cont_box">
        <div class="table_box">
          <el-card class="box-card">
            <div v-for="(item, index) in businesslist" :key="index" class="text item">
              <span class="card-title">{{ item.name }}</span>
              <div class="card-text">{{ item.text }}</div>
            </div>
          </el-card>
        </div>
      </div>
    </div>



  </div>
</template>
<script>


import crypto from '@/utils/crypto'
import { getIndustry } from "../../../api/companyApi";
export default {

  data() {
    return {
      businesslist: [{ name: 'Industry Description:', text: '' },
      { name: 'ISIC Code:', text: '' },
      { name: 'ISIC Code Description:', text: '' },
      { name: 'NACE Code:', text: '' },
      { name: 'NACE Code Description:', text: '' },
      { name: 'NAICS Code:', text: '' },
      { name: 'NAICS Code Description:', text: '' },
      { name: 'No. of Similar Co in the region:', text: '' },
      ],
      loginStatus: false,//登录状态
  
      icon: '',
     

    }
  },
  computed: {
    openMenu: {
      get() {
        return this.menuTitleList.map((i) => {
          return i.title;
        })
      },
      set() { }

    }
  },
  watch: {

    $route: {
    
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.getlist()
  },

  methods: {
    getlist() {
      const base = JSON.parse(sessionStorage.getItem('base'))
      const id3a = this.$route.query.id3a
      const companyCountry = this.$route.query.companyCountry
      const params = {

        operatCode: base.base.operatCode,
        entclasscode: base.base.entclasscode,
        isic: base.current.isicCode,
        // id3a: '156107587326',
        // companyCountry: JSON.parse(sessionStorage.getItem("comany")).company_country,
        aaaId: id3a,
        countryCode: companyCountry ,
      }
      getIndustry(params).then(
        res => {
          let data = JSON.parse(crypto.decrypt(res))
           console.log(data);
          if ( data.code) {
          

            this.businesslist.map((item, index) => {
        
              if (item.name == 'Industry Description:') {
                item.text = data.data.industryDes
              }
              if (item.name == 'ISIC Code:') {
                item.text = data.data.isicCode
              }
              if (item.name == 'ISIC Code Description:') {
                item.text = data.data.isicDes
              }
              if (item.name == 'NACE Code:') {
                item.text = data.data.naceCode
              }
              if (item.name == 'NACE Code Description:') {
                item.text = data.data.naceDes
              }
              if (item.name == 'NAICS Code:') {
                item.text = data.data.naicsCode
              }
              if (item.name == 'NAICS Code Description:') {
                item.text = data.data.naicsDes
              }
              if (item.name == 'No. of Similar Co in the region:') {
                item.text = data.data.region
              }
            })
          } else {
            this.businesslist = []
          }

        }
      )


    },

  
   
    loginPop() {
      console.log(this.loginStatus);

    },
  },

}
</script>
<style scoped lang="less">
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}

.Company Snapshot {}

.Country Data {}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 69px;

  .title-left {
    display: flex;
    font-size: 16px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #022955;
  }
}

.table {
  margin-top: 20px;
}

.el-card {
  border: none;
}

.el-card.is-always-shadow {
  box-shadow: none;

  /deep/ .el-card__body {
    padding: 0px;
  }
}

.card-title {
  font-size: 16px;
  font-family: ArialMT;
  color: #8497AB;
  width: 274px;
  height: 24px;
  margin-right: 40px;
}

.text {
  font-size: 16px;
  font-family: ArialMT;
  color: #022955;
  display: flex;
  align-items: center;
  padding: 20px 0 20px 10px;
  border-bottom: 1px solid #E7EAED;
}

.text:nth-last-of-type(1) {
  border-bottom: none;
}

.card-text {
  width: 736px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #022955;
  line-height: 27px;
}
@media (max-width: 821px) {

.text{
 flex-wrap: wrap;
 padding-bottom: 10px !important;
}


.title{
  margin-top: 20px !important;
}
.card-title {
  font-size: 14px !important;
      color:  #022955 !important;
}
.card-text {
   font-size: 12px !important;
   color: #8497AB !important;
}

}
</style>